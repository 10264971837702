import React, { useEffect, useReducer, useState } from "react";
import { ButtonSectionStyle, ContentPaddingStyle } from "../../styles";
import {
  Button,
  Card,
  Chip,
  FormLabel,
  Grid,
  Icon,
  Select,
  SelectItem,
  Spinner,
  TextField,
  Typography,
  designTokens,
} from "@platform-ui/design-system";
import {
  initialState as accountInfoInitialState,
  reducer as accountInfoReducer,
} from "../../stores/AccountInfo";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useToast } from "../../../../../ToastProvider";
import { useIdentity } from "../../../../../IdentityProvider";
import Connect from "../../../../../Connect/Connect";
import { AllowInstallationModal } from "./modals/AllowInstallationModal";
import { PopoverComp } from "./modals/PopoverComp";
import { ObjectsListModel } from "./modals/ObjectsListModal";
import { useForm } from "react-hook-form";
import { zuoraObjectsList } from "./zuoraObjectsList";

export interface ZuoraObjectsMetaDataType {
  objectName: string;
  id: number;
}

const getDatetime = (
  datetime: string | Number,
  locale: string,
  timezone: string
) => {
  return Intl.DateTimeFormat(locale, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: timezone,
    timeZoneName: "short",
  }).format(new Date(Number(datetime)));
};

const AccountInfo = () => {
  const [regions, setRegions] = useState([]);
  const { setToast } = useToast();
  const [provisionStatusMessage, setProvisionStatusMessage] = useState("");
  const [objectsList, setObjectsList] = useState<ZuoraObjectsMetaDataType[]>([]);
  const [selectedObjects, setSelectedObjects] = useState<string[]>([]);
  const [provisionCompletionStatus, setProvisionCompletionStatus] =
    useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [accountInfoFormState, accountInfoFormDispatch] = useReducer(
    accountInfoReducer,
    accountInfoInitialState
  );
  const [isClickedClosedIcon, setIsClickedClosedIcon] = useState(false); // useState for status popover
  const { identity, zdsLocale, timezone } = useIdentity();
  const [loadingProvisionStatus, setLoadingProvisionStatus] = useState(true);
  const isLocal = window.location.host.includes("localhost");
  const proxyPath = `${isLocal ? "http://localhost:8080" : ""
    }/platform/gateway-proxy-v2`;

  const {
    control,
    register,
    formState: { errors },
  } = useForm();

  const setConnectionStatusMessage = () => {
    if (accountInfoFormState.alreadyInstalled) {
      if (!provisionCompletionStatus) {
        setProvisionStatusMessage(
          "Secure Share setup has been initiated. You will see a status update once your share is ready to access."
        );
      } else {
        setProvisionStatusMessage(
          "Secure Share setup is successfully installed and ready to access."
        );
      }
    }
  };

  const fetchProvisionCompletionStatus = async () => {
    setLoadingProvisionStatus(true);
    try {
      const response = await Connect.proxyCall(
        `${proxyPath}/shares/provision/completionstatus/${identity?.tenantId}`,
        "GET",
        undefined,
        { Accept: "application/json", "Content-Type": "application/json" }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      setProvisionCompletionStatus(await response.json());
    } catch (error) {
      Connect.log(
        `Error fetching snowflake provision completion status: ${error}`
      );
      setToast({
        message:
          "Error fetching snowflake provision completion status! Please refresh the page and try again later!!!",
        severity: "error",
        keyRender: Date.now(),
      });
    } finally {
      setLoadingProvisionStatus(false);
    }
  };

  const fetchAccountInfo = async () => {
    try {
      const response = await Connect.proxyCall(
        `${proxyPath}/shares/accountinfo`,
        "GET",
        undefined,
        {
          Accept: "application/json",
          "Content-Type": "application/json",
          "zuora-tenant-id": identity?.tenantId,
        }
      );
      const payload = await response.json();
      if (payload?.status === 500) {
        // Note(Duc): this 500 error returned in the response indicates the tenant does not have SNOWFLAKE created in tenant registry
        accountInfoFormDispatch({ type: "loadAccountInfo", payload: {} });
      } else {
        accountInfoFormDispatch({ type: "loadAccountInfo", payload });
      }
    } catch (error) {
      Connect.log(`Error fetching snowflake account info: ${error}`);
      setToast({
        message:
          "Error fetching snowflake account info! Please refresh the page and try again later!!!",
        severity: "error",
        keyRender: Date.now(),
      });
    }
  };

  const fetchObjectsList = async () => {
    try {
      const response = await Connect.proxyCall(
        `${proxyPath}/zuora_object/`,
        "GET",
        undefined,
        {
          Accept: "application/json",
          "Content-Type": "application/json",
          "zuora-tenant-id": identity?.tenantId,
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch object list.");
      }
      const data = await response.json();
      const objectsList: ZuoraObjectsMetaDataType[] =
        data?.zobjects?.map((objectName: string, index: number) => ({
          objectName,
          id: index + 1,
        })) || [];
      setObjectsList(objectsList);
    } catch (error) {
      Connect.log(
        `Error fetching objects list for snowflake connector: ${error}`
      );
      setToast({
        message: "Error fetching objects list! Please try again later.",
        severity: "error",
        keyRender: Date.now(),
      });
      setObjectsList(
        zuoraObjectsList?.zobjects?.map((objectName: string, index: number) => ({
          objectName,
          id: index + 1,
        }))
      );
    }
  };

  const fetchRegions = async () => {
    try {
      const response = await Connect.proxyCall(
        `${proxyPath}/shares/regions`,
        "GET",
        undefined,
        { Accept: "application/json", "Content-Type": "application/json" }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      setRegions(await response.json());
    } catch (error) {
      Connect.log(`Error fetching snowflake regions: ${error}`);
      setToast({
        message:
          "Error fetching snowflake regions! Please refresh the page and try again later!!!",
        severity: "error",
        keyRender: Date.now(),
      });
    }
  };

  useEffect(() => {
    fetchRegions();
    fetchAccountInfo();
    fetchProvisionCompletionStatus();
    fetchObjectsList();
  }, []);

  const provisionTenantShare = async () => {
    const { region, accountEdition: snowflakeAccountEdition } =
      accountInfoFormState;

    const tenantId = identity?.tenantId;

    const businessCritical = snowflakeAccountEdition === "Business Critical";
    const serviceType = "billing";
    const shareObjects = selectedObjects.length ? selectedObjects : null;
    const shareLatency = "EVERYDAY";
    const warehouse = false;

    Connect.proxyCall(
      `${proxyPath}/shares/provision`,
      "POST",
      {
        tenantId,
        serviceType,
        region,
        shareObjects,
        shareLatency,
        warehouse,
        businessCritical,
      },
      { Accept: "application/json", "Content-Type": "application/json" }
    )
      .then((response) => {
        if (response.ok) {
          setProvisionStatusMessage(
            "Secure Share setup has been initiated. You will see a status update once your share is ready to access."
          );
          setToast({
            message:
              "Account information was saved and provisioning was initiated successfully.",
            severity: "success",
            keyRender: Date.now(),
          });
        } else {
          setToast({
            message:
              "We encountered a problem with setting up the share. Please verify the details and reinitiate the setup.",
            severity: "error",
            keyRender: Date.now(),
          });
        }
      })
      .catch(() => {
        setToast({
          message:
            "We encountered a problem with setting up the share. Please verify the details and reinitiate the setup.",
          severity: "error",
          keyRender: Date.now(),
        });
      });
  };

  const saveAccountInfo = async () => {
    const {
      region: snowflakeAccountRegion,
      accountName: snowflakeAccountName,
      accountEdition: snowflakeAccountEdition,
    } = accountInfoFormState;

    Connect.proxyCall(
      `${proxyPath}/shares/accountinfo`,
      "POST",
      {
        snowflakeAccountRegion,
        snowflakeAccountName,
        snowflakeAccountEdition,
        installationUserFirstName: identity?.firstName || "Admin",
        installationUserLastName: identity?.lastName || "Admin",
        installationUserEmail: identity?.workEmail || "admin@zuora.com",
      },
      {
        Accept: "application/json",
        "Content-Type": "application/json",
        "zuora-tenant-id": identity?.tenantId,
      }
    )
      .then((response) => {
        accountInfoFormDispatch({ type: "setValidating", payload: false });
        if (response.ok) {
          fetchAccountInfo();
          setConfirmationModalOpen(false);
          provisionTenantShare();
        } else {
          setToast({
            message:
              "We encounted a problem installing your account information. Please try again later.",
            severity: "error",
            keyRender: Date.now(),
          });
        }
      })
      .catch(() => {
        accountInfoFormDispatch({ type: "setValidating", payload: false });
        setToast({
          message:
            "We encounted a problem installing your account information. Please try again later.",
          severity: "error",
          keyRender: Date.now(),
        });
      });
  };

  const validateAccountInfo = async () => {
    const {
      region: snowflakeAccountRegion,
      accountName: snowflakeAccountName,
      accountEdition: snowflakeAccountEdition,
    } = accountInfoFormState;

    accountInfoFormDispatch({ type: "setValidating", payload: true });
    Connect.proxyCall(
      `${proxyPath}/shares/accountinfo/validate`,
      "POST",
      {
        snowflakeAccountRegion,
        snowflakeAccountName,
        snowflakeAccountEdition,
        installationUserFirstName: identity?.firstName || "Admin",
        installationUserLastName: identity?.lastName || "Admin",
        installationUserEmail: identity?.workEmail || "admin@zuora.com",
      },
      { Accept: "application/json", "Content-Type": "application/json" }
    )
      .then((response) => {
        if (response.ok) {
          saveAccountInfo();
        } else {
          accountInfoFormDispatch({ type: "setValidating", payload: false });
          setToast({
            message:
              "We encounted a problem verifying your account information. Please try again later.",
            severity: "error",
            keyRender: Date.now(),
          });
        }
      })
      .catch(() => {
        accountInfoFormDispatch({ type: "setValidating", payload: false });
        setToast({
          message:
            "We encounted a problem verifying your account information. Please try again later.",
          severity: "error",
          keyRender: Date.now(),
        });
      });
  };

  const hasMissingAccountInfo = () => {
    const { region, accountName, accountEdition } = accountInfoFormState;
    return !region || !accountName || !accountEdition;
  };

  const InstallButtonSection = () => {
    setConnectionStatusMessage();
    return (
      <ButtonSectionStyle>
        <Button
          disabled={
            hasMissingAccountInfo() ||
            accountInfoFormState.alreadyInstalled ||
            provisionCompletionStatus
          }
          dsOnClick={() => {
            setConfirmationModalOpen(true);
          }}
        >
          Install
        </Button>
      </ButtonSectionStyle>
    );
  };

  const InstalledBySection = () => {
    return (
      <ContentPaddingStyle>
        <div>
          <Typography>
            <b>Installed By:</b>
          </Typography>
        </div>
        <div>
          <Typography>{`${accountInfoFormState.installerFirstName} ${accountInfoFormState.installerLastName} (${accountInfoFormState.installerEmail})`}</Typography>
        </div>
        <div>
          <Typography>
            {getDatetime(accountInfoFormState.installDate, zdsLocale, timezone)}
          </Typography>
        </div>
      </ContentPaddingStyle>
    );
  };

  console.log({ objectsList, selectedObjects }); //console logs

  const content = (
    <Grid container item xs direction="column" spacing={3}>
      <Grid item>
        <Select
          disabled={accountInfoFormState.alreadyInstalled}
          label="Snowflake Account Region"
          labelPostfix={
            <>
              <Icon body="asterisk" fontSize="small" color="error" />
              <Tooltip
                arrow
                placement="top-start"
                title={
                  <Typography variant="subtitle3">
                    To find this information, navigate to your Snowflake account
                    and run the following command:
                    <br />
                    <strong>SELECT CURRENT_REGION();</strong>
                  </Typography>
                }
              >
                <span>
                  <Icon body="info_outline" fontSize="small" />
                </span>
              </Tooltip>
            </>
          }
          placeholder="Select One"
          value={accountInfoFormState.region || ""}
          dsOnChange={(e) => {
            accountInfoFormDispatch({
              type: "setRegion",
              payload: (e.target.value as any) || "",
            });
          }}
        >
          {regions.sort().map((item) => (
            <SelectItem key={item} value={item}>
              {item}
            </SelectItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <TextField
          disabled={accountInfoFormState.alreadyInstalled}
          label={
            <>
              <FormLabel>Snowflake Account Locator</FormLabel>
              <span>
                <Icon body="asterisk" fontSize="small" color="error" />
              </span>
              <Tooltip
                arrow
                placement="top-start"
                title={
                  <Typography variant="subtitle3">
                    To get your Snowflake account locator, refer to your account
                    URL. It's the part before the first dot, like this:
                    <br />
                    <strong>
                      https://&lt;account_locator&gt;.&lt;cloud_region&gt;.snowflakecomputing.com/
                    </strong>
                  </Typography>
                }
              >
                <span>
                  <Icon body="info_outline" fontSize="small" />
                </span>
              </Tooltip>
            </>
          }
          dsOnChange={({ target }) => {
            accountInfoFormDispatch({
              type: "setAccountName",
              payload: target.value,
            });
          }}
          value={accountInfoFormState.accountName || ""}
        />
      </Grid>
      <Grid item>
        <Select
          disabled={accountInfoFormState.alreadyInstalled}
          label="Snowflake Account Edition"
          labelPostfix={
            <>
              <Icon body="asterisk" fontSize="small" color="error" />
              <Tooltip
                arrow
                placement="top-start"
                title={
                  <Typography variant="subtitle3">
                    To find this information, navigate to your Snowflake
                    account:
                    <ul>
                      <li>
                        For <strong>classic UI</strong>, click the account name
                        in the top right corner and check the edition in account
                        information.
                      </li>
                      <li>
                        For <strong>Snowsight UI</strong>, click the account
                        name in the bottom left corner and hover over the
                        account information to get the edition.
                      </li>
                    </ul>
                  </Typography>
                }
              >
                <span>
                  <Icon body="info_outline" fontSize="small" />
                </span>
              </Tooltip>
            </>
          }
          placeholder="Select One"
          value={accountInfoFormState.accountEdition || ""}
          dsOnChange={(e) => {
            accountInfoFormDispatch({
              type: "setAccountEdition",
              payload: (e.target.value as any) || "",
            });
          }}
        >
          <SelectItem value="Enterprise">Enterprise</SelectItem>
          <SelectItem value="Business Critical">Business Critical</SelectItem>
        </Select>
      </Grid>
      <Grid container item xs direction="column" spacing={0.5}>
        <Grid item>
          <Typography variant="titleM">
            Select Zuora Objects to Receive Data
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            sx={{ color: designTokens.colors.coolGray400 }}
          >
            Select supported objects for Snowflake
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <ObjectsListModel
          control={control}
          rows={objectsList}
          errors={errors}
          register={register}
          onSelectionChange={(selectedObjectNames: string[]) =>
            setSelectedObjects(selectedObjectNames)
          }
        />
      </Grid>
      <Grid item>
        {!accountInfoFormState.alreadyInstalled ? (
          <InstallButtonSection />
        ) : (
          <InstalledBySection />
        )}
      </Grid>
      {confirmationModalOpen && (
        <AllowInstallationModal
          openInstallationModal={confirmationModalOpen}
          onClose={() => setConfirmationModalOpen(false)}
          onInstallation={() => validateAccountInfo()}
          accountInfoFormState={accountInfoFormState}
          identity={identity}
        />
      )}
    </Grid>
  );

  const connectionStatusChip = accountInfoFormState.alreadyInstalled && (
    <PopoverComp
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      onFirstOpen={() => {
        setIsClickedClosedIcon(false);
      }}
      anchor={
        <Chip
          label={
            <Box display="flex" alignItems="center" gap={0.5}>
              {provisionCompletionStatus ? "Active" : "In Progress"}
              <Icon
                body="info"
                variant="filled"
                color="inherit"
                fontSize="small"
              />
            </Box>
          }
          state={provisionCompletionStatus ? "success" : "primary"}
          size="medium"
        />
      }
      body={
        <Grid
          container
          sx={{
            width: "300px",
            padding: "12px 18px",
          }}
          spacing={1}
          direction="column"
        >
          <Grid item container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="title" sx={{ fontWeight: 600 }}>
                {provisionCompletionStatus
                  ? "Setup Successful"
                  : "Setup In Progress"}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                onClick={() => setIsClickedClosedIcon(true)}
              >
                <Icon body="close" variant="outlined" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ fontWeight: 400, opacity: 0.6 }}>
              {provisionStatusMessage}
            </Typography>
          </Grid>
        </Grid>
      }
      displayPopover={!isClickedClosedIcon}
      popoverId="connectionStatus-popover"
    />
  );

  return (
    <Card
      id="accountInfoSection"
      header={
        <Grid container item xs direction="column" spacing={0.5}>
          <Grid container item alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="titleL">
                Snowflake Account Information
              </Typography>
            </Grid>
            <Grid item>{connectionStatusChip}</Grid>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              Please provide your Snowflake account information.
            </Typography>
          </Grid>
        </Grid>
      }
      body={loadingProvisionStatus ? <Spinner /> : content}
      variant="outlined"
    />
  );
};

export default AccountInfo;
