import React from "react";
import { Alert, Card, Spinner } from "@platform-ui/design-system";
import { ContentPaddingStyle } from "./styles";

import ConfigInfo from "./components/ConfigInfo";
import AccountInfo from "./components/AccountInfo";
import { useIdentity } from "../../../IdentityProvider";

import { useFlipperHook } from "../../../Tables/helpers";

const ConnectionSettings = () => {
  const { hasPermission } = useIdentity();
  const [snowflakeConfigEnabled, snowflakeConfigLoading] = useFlipperHook(
    "enable_snowflake_config"
  );

  if (!hasPermission('permission.SnowflakeSecureShare')) {
    return (
      <Alert
        center
        variant="outlined"
        severity="warning"
        body="Missing required permission SnowflakeSecureShare"
        open={true}
      />
    );
  }

  const getAccountInfoSection = () => {
    return hasPermission('permission.SnowflakeSecureShare') ? (
      <AccountInfo />
    ) : (
      <Alert
        center
        variant="outlined"
        severity="info"
        body="Enable permission SnowflakeSecureShare to see Snowflake Account Information"
        open={true}
      />
    );
  };

  const getConfigInfoSection = () => {
    return hasPermission('permission.PoweredBySnowflake') ? (
      <ConfigInfo />
    ) : (
      <Alert
        center
        variant="outlined"
        severity="info"
        body="Enable permission PoweredBySnowflake to configure Zuora Applications"
        open={true}
      />
    );
  };

  return (
    <Card
      id="snowflake-connection-settings"
      body={
        snowflakeConfigLoading ? (
          <Spinner />
        ) : (
          <>
            <ContentPaddingStyle>{getAccountInfoSection()}</ContentPaddingStyle>
            <br />
            {snowflakeConfigEnabled && (
              <ContentPaddingStyle>
                {getConfigInfoSection()}
              </ContentPaddingStyle>
            )}
          </>
        )
      }
    />
  );
};

export default ConnectionSettings;
