import React from "react";
import {
    Button,
    Icon,
    Modal,
    ModalActions,
    ModalTitle,
    Spinner,
} from "@platform-ui/design-system";
import { Box, Typography } from "@mui/material";
import { IdentityResponse } from "../../../../../../IdentityProvider";
import { AccountInfoState } from "../../../stores/AccountInfo/reducer";

export const AllowInstallationModal = ({
    openInstallationModal,
    onClose,
    onInstallation,
    accountInfoFormState,
    identity,
}: {
    openInstallationModal: boolean;
    onClose: () => void;
    onInstallation: () => void;
    accountInfoFormState: AccountInfoState;
    identity: IdentityResponse;
}) => {
    return (
        <Modal
            id="allowInstallation-modal-id"
            open={openInstallationModal}
            maxWidth="sm"
            variant="dialog"
            fullWidth
            dsOnClose={onClose}
            header={
                <ModalTitle
                    variant="dialog"
                    icon={<Icon body="info" color="info" variant="filled" />}
                >
                    <Typography variant="body1" sx={{ fontWeight: "600" }}>
                        Allow Zuora to Connect & Transmit Data To Snowflake
                    </Typography>
                </ModalTitle>
            }
            body={
                accountInfoFormState.validating ? (
                    <Box
                        sx={{
                            marginRight: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Spinner size="small" variant="circular" />
                    </Box>
                ) : (
                    <Box>
                        <Typography variant="body2" sx={{ pb: 2 }}>
                            By clicking "Allow & Install" below you are allowing Zuora to
                            transmit your Zuora data to the Snowflake account that you have
                            provided. This action cannot be reverted.
                        </Typography>
                        {[
                            { label: "Snowflake Region", value: accountInfoFormState.region },
                            {
                                label: "Snowflake Account Name",
                                value: accountInfoFormState.accountName,
                            },
                            {
                                label: "Snowflake Account Edition",
                                value: accountInfoFormState.accountEdition,
                            },
                            {
                                label: "Installed By User",
                                value: `${identity?.firstName} ${identity?.lastName} (${identity?.workEmail})`,
                            },
                        ].map(({ label, value }) => (
                            <Typography key={label} variant="body2">
                                <span style={{ fontWeight: 500 }}>{label}:</span> {value}
                            </Typography>
                        ))}
                    </Box>
                )
            }
            footer={
                <ModalActions>
                    <Button
                        disabled={accountInfoFormState.validating}
                        variant="outlined"
                        body="Cancel"
                        dsOnClick={onClose}
                    />
                    <Button
                        body="Allow & Install"
                        disabled={accountInfoFormState.validating}
                        dsOnClick={onInstallation}
                    />
                </ModalActions>
            }
        />
    );
};
