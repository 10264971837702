import styled from "@emotion/styled";
import { PopoverOrigin } from "@mui/material";
import { Popover } from "@platform-ui/design-system";
import { debounce } from "lodash";
import React, { ReactElement, useCallback, useState } from "react";

const AnchorWrapper = styled.div<{ customStyle: object }>(
  ({ customStyle = {} }) => ({
    cursor: "pointer",
    ...customStyle,
  })
);

export const PopoverComp = ({
  onFirstOpen,
  anchor,
  body,
  popoverId,
  displayPopover,
  transformOrigin,
  anchorOrigin,
  anchorStyle = {},
}: {
  onFirstOpen: () => void;
  anchor: ReactElement;
  body: ReactElement;
  popoverId: string;
  displayPopover: boolean;
  transformOrigin: PopoverOrigin;
  anchorOrigin: PopoverOrigin;
  anchorStyle?: object;
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleMouseLeave = useCallback(
    debounce(() => {
      setAnchorEl(null);
      setOpen(false);
    }, 300),
    []
  );

  const handleMouseEnter = useCallback(() => {
    handleMouseLeave.cancel();
    setOpen(true);
  }, [handleMouseLeave]);

  return (
    <>
      <AnchorWrapper
        customStyle={anchorStyle}
        onMouseEnter={(event: any) => {
          setAnchorEl(event.currentTarget);
          handleMouseEnter();
          onFirstOpen();
        }}
        onMouseLeave={handleMouseLeave}
      >
        {anchor}
      </AnchorWrapper>
      {displayPopover && open && (
        <Popover
          arrow
          open={Boolean(anchorEl)}
          transformOrigin={transformOrigin}
          anchorOrigin={anchorOrigin}
          paperProps={{
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
          }}
          disableRestoreFocus
          pointerEventsNone
          body={body}
          anchorEl={anchorEl}
          a11yId={popoverId}
        />
      )}
    </>
  );
};
