import { Box, FormGroup, Tooltip } from "@mui/material";
import {
    Button,
    Card,
    Checkbox,
    FormLabel,
    Grid,
    Icon,
    Modal,
    ModalTitle,
    Typography,
    designTokens,
} from "@platform-ui/design-system";
import React, { useState } from "react";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import { DataGrid, GridPaginationModel, GridRowSelectionModel, GridToolbar } from "@mui/x-data-grid";
import { Destination } from "@prequel/react";
import { ZuoraObjectsMetaDataType } from "../AccountInfo";

interface ObjectsModelPropData {
    control: Control<FieldValues, any>;
    rows: ZuoraObjectsMetaDataType[];
    errors: FieldErrors<FieldValues>;
    register: (name: keyof Destination, options?: any) => any;
    onSelectionChange: (selectedObjects: string[]) => void;
}

export const ObjectsListModel = ({
    control,
    rows,
    errors,
    register,
    onSelectionChange,
}: ObjectsModelPropData) => {
    const [
        allCurrentAndFutureModelsSelected,
        setAllCurrentAndFutureModelsSelected,
    ] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] =
        useState<GridRowSelectionModel>([]);
    const [prevRowSelection, setPrevRowSelectionModel] =
        useState<GridRowSelectionModel>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });

    const onClose = () => {
        setRowSelectionModel(prevRowSelection);
        setModalOpen(false);
    };

    const handleRowSelectionChange = (
        newRowSelectionModel: GridRowSelectionModel
    ) => {
        setRowSelectionModel(newRowSelectionModel);
        const selectedObjectNames = newRowSelectionModel // Map selected IDs to their objectName values
            .map((id) => rows.find((row) => row.id === id)?.objectName)
            .filter(Boolean) as string[];
        onSelectionChange(selectedObjectNames);
    };

    return (
        <Controller
            name="connector-select-objects"
            control={control}
            render={() => (
                <Box>
                    <FormLabel>
                        Billing Standard Objects
                        <span>
                            <Icon body="asterisk" fontSize="small" color="error" />
                        </span>
                        <Tooltip
                            arrow
                            placement="top-start"
                            title={
                                <Typography variant="subtitle3">
                                    This is the complete list of objects supported by Zuora Secure
                                    Data Share for Snowflake. However, only objects that the
                                    tenant has permission to access will be included in the Data
                                    Share during provisioning. Custom objects will be included by
                                    default.
                                </Typography>
                            }
                        >
                            <span>
                                <Icon body="info_outline" fontSize="small" />
                            </span>
                        </Tooltip>
                    </FormLabel>
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        {rowSelectionModel?.length > 0 && (
                            <Typography
                                sx={{ marginRight: "1%" }}
                                variant="subtitle3"
                                body={`${rowSelectionModel.length} of ${rows.length} objects selected`}
                            ></Typography>
                        )}
                        <Button
                            sx={{ marginBottom: "1%" }}
                            body={rowSelectionModel.length === 0 ? "Select" : "Reselect"}
                            dsOnClick={() => {
                                setModalOpen(true);
                                if (rowSelectionModel.length > 0) {
                                    setPrevRowSelectionModel(rowSelectionModel);
                                }
                            }}
                            variant={rowSelectionModel.length > 0 ? "text" : "contained"}
                        />
                    </Box>
                    <Box>
                        {!allCurrentAndFutureModelsSelected &&
                            rowSelectionModel.length === 0 && (
                                <Typography
                                    variant="caption"
                                    color={designTokens.colors.red500}
                                    body={
                                        errors &&
                                            errors["enabled_models"] &&
                                            errors["enabled_models"].message
                                            ? (errors["enabled_models"].message as string)
                                            : ""
                                    }
                                />
                            )}
                    </Box>
                    <Modal
                        {...register("enabled_models", {
                            validate: (value, formValues) => {
                                if (
                                    !allCurrentAndFutureModelsSelected &&
                                    rowSelectionModel.length === 0
                                ) {
                                    return "This is a required field";
                                }
                                return true;
                            },
                        })}
                        maxWidth="sm"
                        fullWidth
                        open={modalOpen}
                        id="connector-select-models"
                        dsOnClose={onClose}
                        header={
                            <ModalTitle dsOnClose={onClose} subtitle="Billing Standard Objects supported by Snowflake">
                                Select Billing Standard Objects
                            </ModalTitle>
                        }
                        body={
                            <DataGrid
                                disableRowSelectionOnClick
                                pagination
                                pageSizeOptions={[5, 10, 20]}
                                paginationModel={paginationModel}
                                onPaginationModelChange={setPaginationModel}
                                isRowSelectable={() => !allCurrentAndFutureModelsSelected}
                                sx={{
                                    ".MuiDataGrid-toolbarContainer": {
                                        display: "block",
                                    },
                                    ".MuiTablePagination-selectLabel": {
                                        margin: "0px",
                                    },
                                    ".MuiTablePagination-displayedRows": {
                                        margin: "0px",
                                    },
                                    ".MuiDataGrid-filler": {
                                        display: "none",
                                    },
                                }}
                                rows={rows}
                                disableColumnFilter
                                checkboxSelection
                                disableColumnSelector
                                disableDensitySelector
                                disableColumnMenu
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        csvOptions: { disableToolbarButton: true },
                                        printOptions: { disableToolbarButton: true },
                                    },
                                    filterPanel: {
                                        disableAddFilterButton: true,
                                    },
                                }}
                                onRowSelectionModelChange={handleRowSelectionChange}
                                rowSelectionModel={rowSelectionModel}
                                columns={[
                                    {
                                        field: "id",
                                        headerName: "Number",
                                    },
                                    {
                                        field: "objectName",
                                        headerName: "Object Name",
                                        sortable: true,
                                        width: 200,
                                    },
                                ]}
                            />
                        }
                        footer={
                            <Card
                                id="select-future-objects"
                                body={
                                    <FormGroup>
                                        <Checkbox
                                            label={
                                                <Typography variant="body2">
                                                    Select all current and future objects
                                                </Typography>
                                            }
                                            dsOnChange={(e) => {
                                                setAllCurrentAndFutureModelsSelected(e.target.checked);
                                                if (e.target.checked) {
                                                    setRowSelectionModel(
                                                        Array.from(
                                                            { length: rows.length },
                                                            (_, index) => index + 1
                                                        )
                                                    );
                                                } else {
                                                    setRowSelectionModel([]);
                                                }
                                            }}
                                            checked={allCurrentAndFutureModelsSelected}
                                        />
                                        <Typography
                                            variant="caption"
                                            color={designTokens.colors.coolGray300}
                                        >
                                            Any new objects added in the future will be synced automatically
                                        </Typography>
                                        <Grid container direction="row" justify="flex-end">
                                            <Grid item>
                                                <Button
                                                    dsOnClick={(e) => {
                                                        setRowSelectionModel(prevRowSelection);
                                                        setAllCurrentAndFutureModelsSelected(false);
                                                        setModalOpen(false);
                                                    }}
                                                    body="Cancel"
                                                    variant="tertiary"
                                                    size="medium"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    dsOnClick={(e) => {
                                                        setModalOpen(false);
                                                    }}
                                                    body="Done"
                                                    size="medium"
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                }
                            ></Card>
                        }
                    />
                </Box>
            )}
        />
    );
};
