export const zuoraObjectsList = {
  zobjects: [
    "Account",
    "AccountingCode",
    "AccountingPeriod",
    "AccountingPeriodOrgMap",
    "Amendment",
    "ApplicationGroup",
    "ARTransaction",
    "BillingPreviewRunResult",
    "BillingRun",
    "BillingRunOrgMap",
    "BillingTransaction",
    "BookingTransaction",
    "ChargeMetrics",
    "ConsolidatedTransaction",
    "Contact",
    "ContactSnapshot",
    "Country",
    "CreditBalanceAdjustment",
    "CreditBalanceAdjustmentFxData",
    "CreditMemo",
    "CreditMemoApplication",
    "CreditMemoApplicationFxData",
    "CreditMemoApplicationItem",
    "CreditMemoApplicationItemFxData",
    "CreditMemoFxData",
    "CreditMemoItem",
    "CreditMemoItemFxData",
    "CreditMemoPart",
    "CreditMemoPartFxData",
    "CreditMemoPartItem",
    "CreditMemoPartItemFxData",
    "CreditTaxationItem",
    "CreditTaxationItemFxData",
    "CustomObjects",
    "DailyConsumptionSummary",
    "DATAQUALITYLOGS",
    "DebitMemo",
    "DebitMemoFxData",
    "DebitMemoItem",
    "DebitMemoItemFxData",
    "DebitTaxationItem",
    "DebitTaxationItemFxData",
    "Feature",
    "FXCustomRate",
    "GuidedUsage",
    "Invoice",
    "InvoiceAdjustment",
    "InvoiceAdjustmentFxData",
    "InvoiceFxData",
    "InvoiceHistory",
    "InvoiceItem",
    "InvoiceItemAdjustment",
    "InvoiceItemAdjustmentFxData",
    "InvoiceItemFxData",
    "InvoicePayment",
    "InvoicePaymentFxData",
    "JournalEntry",
    "JournalEntryItem",
    "JournalRun",
    "JournalRunOrgMap",
    "MinCommitPeriod",
    "MinCommitTransaction",
    "NonSubscriptionInvoiceItem",
    "OrderAction",
    "OrderActionRatePlan",
    "OrderContact",
    "OrderDeltaMrr",
    "OrderDeltaTcb",
    "OrderDeltaTcv",
    "OrderElp",
    "OrderItem",
    "OrderLineItem",
    "OrderMrr",
    "OrderQuantity",
    "Orders",
    "OrderTcb",
    "OrderTcv",
    "Organization",
    "Payment",
    "PaymentApplication",
    "PaymentApplicationFxData",
    "PaymentApplicationItem",
    "PaymentApplicationItemFxData",
    "PaymentFxData",
    "PaymentMethod",
    "PaymentMethodSnapshot",
    "PaymentMethodTransactionLog",
    "PaymentPart",
    "PaymentPartFxData",
    "PaymentPartItem",
    "PaymentPartItemFxData",
    "PaymentRun",
    "PaymentRunOrgMap",
    "PaymentSchedule",
    "PaymentScheduleItem",
    "PaymentTransactionLog",
    "PrepaidBalance",
    "PrepaidBalanceFund",
    "PrepaidBalanceTransaction",
    "ProcessedUsage",
    "Product",
    "ProductFeature",
    "ProductOrgMap",
    "ProductRatePlan",
    "ProductRatePlanCharge",
    "ProductRatePlanChargeOrgMap",
    "ProductRatePlanChargeTier",
    "ProductRatePlanChargeTierOrgMap",
    "ProductRatePlanOrgMap",
    "Ramp",
    "RampInterval",
    "RampIntervalDeltaMetrics",
    "RampIntervalDeltaMrr",
    "RampIntervalDeltaQuantity",
    "RampIntervalMetrics",
    "RampIntervalMrr",
    "RatePlan",
    "RatePlanCharge",
    "RatePlanChargeTier",
    "RatingResult",
    "RealTimeRatingProcessedUsage",
    "Refund",
    "RefundApplication",
    "RefundApplicationFxData",
    "RefundApplicationItem",
    "RefundApplicationItemFxData",
    "RefundFxData",
    "RefundInvoicePayment",
    "RefundInvoicePaymentFxData",
    "RefundPart",
    "RefundPartFxData",
    "RefundPartItem",
    "RefundPartItemFxData",
    "RefundTransactionLog",
    "REVENUEACCOUNTINGSUMMARY",
    "REVENUEBUNDLECONFIGURATION",
    "REVENUECALENDAR",
    "RevenueChargeSummary",
    "RevenueChargeSummaryItem",
    "REVENUECONTRACT",
    "REVENUECONTRACTACCOUNTINGENTRIES",
    "REVENUECONTRACTACCOUNTINGENTRIESDELETED",
    "REVENUECONTRACTACCOUNTINGSEGMENTS",
    "REVENUECONTRACTACCOUNTINGTYPE",
    "REVENUECONTRACTACTIONS",
    "REVENUECONTRACTAPPROVALS",
    "REVENUECONTRACTBILLSDIMENSIONS",
    "REVENUECONTRACTBILLSFACTS",
    "REVENUECONTRACTCOSTDIMENSIONS",
    "REVENUECONTRACTCOSTFACTS",
    "REVENUECONTRACTHOLDS",
    "REVENUECONTRACTLINESDIMENSIONS",
    "REVENUECONTRACTLINESFACTS",
    "REVENUECONTRACTMJENTRIESDIMENSIONS",
    "REVENUECONTRACTMJENTRIESFACTS",
    "REVENUECONTRACTMODIFICATIONDETAILS",
    "REVENUECONTRACTNETTINGSTATUS",
    "REVENUECONTRACTROLLFORWARD",
    "REVENUECONTRACTVCDIMENSIONS",
    "REVENUECONTRACTVCFACTS",
    "RevenueEvent",
    "RevenueEventCreditMemoItem",
    "RevenueEventDebitMemoItem",
    "RevenueEventInvoiceItem",
    "RevenueEventInvoiceItemAdjustment",
    "RevenueEventItem",
    "RevenueEventItemCreditMemoItem",
    "RevenueEventItemDebitMemoItem",
    "RevenueEventItemInvoiceItem",
    "RevenueEventItemInvoiceItemAdjustment",
    "RevenueEventType",
    "REVENUELINESTAGING",
    "REVENUELOOKUP",
    "REVENUEPERIODHISTORY",
    "REVENUEPERIODS",
    "RevenueSchedule",
    "RevenueScheduleCreditMemoItem",
    "RevenueScheduleDebitMemoItem",
    "RevenueScheduleInvoiceItem",
    "RevenueScheduleInvoiceItemAdjustment",
    "RevenueScheduleItem",
    "RevenueScheduleItemCreditMemoItem",
    "RevenueScheduleItemCreditMemoItemFxData",
    "RevenueScheduleItemDebitMemoItem",
    "RevenueScheduleItemDebitMemoItemFxData",
    "RevenueScheduleItemFxData",
    "RevenueScheduleItemInvoiceItem",
    "RevenueScheduleItemInvoiceItemAdjustment",
    "RevenueScheduleItemInvoiceItemAdjustmentFxData",
    "RevenueScheduleItemInvoiceItemFxData",
    "REVENUESSPBATCHDATA",
    "REVENUETNVLINESSP",
    "State",
    "Subscription",
    "SubscriptionProductFeature",
    "SubscriptionStatusHistory",
    "TaxationItem",
    "TaxationItemFxData",
    "UpdaterAccount",
    "UpdaterBatch",
    "UpdaterDetail",
    "Usage",
    "User",
    "ValidityPeriodSummary",
  ],
};
